/**
 * Module dependencies.
 */

import { NewsExhibitionsProps } from 'src/types/news-exhibitions';
import { ReactElement } from 'react';
import NewsExhibitionsCard from 'src/components/cards/news-exhibitions-card';
import map from 'lodash/map';

/**
 * `Props` type.
 */

type Props = {
  newsExhibitions: NewsExhibitionsProps[];
};

/**
 * `NewsExhibitionsList` component.
 */

const NewsExhibitionsList = ({ newsExhibitions }: Props): ReactElement => {
  const animProps = {
    'data-scroll': 'fade-in-up-small',
    'data-scroll-animation-delay': '150',
    'data-scroll-offset': '20%'
  };

  return (
    <>
      {map(
        newsExhibitions,
        ({ featuredImages, id, lead, slug, title }: NewsExhibitionsProps) => (
          <div
            {...animProps}
            key={id}
          >
            <NewsExhibitionsCard
              description={lead}
              imageUrl={featuredImages}
              slug={slug}
              title={title}
            />
          </div>
        )
      )}
    </>
  );
};

/**
 * Export `NewsExhibitionsList` component.
 */

export default NewsExhibitionsList;
