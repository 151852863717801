/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string;
  params: {
    filter?: {
      [key: string]: any;
    };
    limit: number;
    page: number;
  };
};

/**
 * Export `paginationLimit`.
 */

export const paginationLimit = 12;

/**
 * Export `fetchNewsExhibitions`.
 */

export async function fetchNewsExhibitions({ locale, params }: Query) {
  const endpoint = getApiEndpoint('getNewsExhibitions');

  if (!params.page) {
    return;
  }

  try {
    const currentPage = params?.page ?? 1;
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(locale),
      params: {
        ...params,
        filter: params?.filter
      }
    });

    return {
      data: data?.data?.data,
      nextPage: +currentPage + 1,
      total: data?.data?.total
    };
  } catch (error) {
    handleRequestError(error);
  }
}
