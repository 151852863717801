/**
 * Module dependencies.
 */

import { Button, Loading, Subtitle, Type } from 'src/components/core';
import { Container, media, units } from '@untile/react-components';
import { Market } from 'src/types/markets';
import { PageFields } from 'src/types/page-fields';
import { ReactElement } from 'react';
import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import MarketCard from 'src/components/cards/market-card';
import Metatags from 'src/components/core/metatags';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useMarkets from 'src/api/markets/use-markets';
import usePageFields from 'src/api/page-fields/use-page-fields';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  data: PageFields;
  markets: Market[];
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section<{ isLoading: boolean }>`
  position: relative;

  ${ifProp(
    'isLoading',
    css`
      min-height: 100vh;
    `
  )}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding: 0;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. title    .'
    '. subtitle .'
    '. button   .'
    '. list     .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme(
      'grid.gutterMobile'
    )}px;

  ${media.min('md')`
    grid-template-areas:
      '. title    .      .'
      '. subtitle button .'
      '. list     list   .';
    grid-template-columns: 1fr repeat(2, 5fr) 1fr;
    grid-template-rows: repeat(3, max-content);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1)`
  grid-area: title;

  ${media.min('md')`
    margin-bottom: ${units(1)};
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Subtitle)`
  grid-area: subtitle;

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  grid-area: button;
  margin-bottom: ${units(6)};

  ${media.min('md')`
    align-self: flex-end;
    display: flex;
    justify-self: flex-end;
    margin-bottom: ${units(9)};
  `}
`;

/**
 * `ListWrapper` styled component.
 */

const ListWrapper = styled.div`
  display: grid;
  grid-area: list;
  grid-gap: ${units(2.5)};
  grid-template-columns: 1fr;

  ${media.min('ms')`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('md')`
    grid-gap: ${units(3)};
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `MarketsList` component.
 */

const MarketsList = (props: Props): ReactElement => {
  const { translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const buttonUrl = routeResolver('contactUs');
  const animProps = {
    'data-scroll': 'fade-in-up',
    'data-scroll-offset': '10%'
  };

  const {
    data: pageData,
    isLoading: isLoadingPage,
    isSuccess: isSuccessPage
  } = usePageFields({ initialData: props.data });

  const {
    data: markets,
    isLoading: isMarketsLoading,
    isSuccess: isMarketsSuccess
  } = useMarkets({ initialData: props.markets });

  const isLoading = isLoadingPage || isMarketsLoading;
  const isSuccess = isSuccessPage && isMarketsSuccess;
  const description = pageData?.marketDescription;
  const title = pageData?.marketTitle;

  return (
    <>
      <Metatags
        description={description}
        pageTitle={title}
        title={title}
      />

      <Wrapper isLoading={isLoading}>
        <Loading active={isLoading} />

        {isSuccess && (
          <StyledContainer>
            <Grid>
              <Title {...animProps}>{title}</Title>

              <Description {...animProps}>{description}</Description>

              <ButtonWrapper {...animProps}>
                <Button
                  colorTheme={'secondary'}
                  href={buttonUrl}
                  size={'large'}
                >
                  {translate('common:actions.reachOutToUs')}
                </Button>
              </ButtonWrapper>

              <ListWrapper>
                {map(
                  markets,
                  ({ bgColor, featuredImage, id, slug, title }: Market) => (
                    <div
                      data-scroll={'fade-in-up-small'}
                      data-scroll-animation-delay={'150'}
                      data-scroll-offset={'20%'}
                      key={id}
                    >
                      <MarketCard
                        bgColor={bgColor}
                        imageUrl={featuredImage}
                        slug={slug}
                        title={title}
                      />
                    </div>
                  )
                )}
              </ListWrapper>
            </Grid>
          </StyledContainer>
        )}
      </Wrapper>
    </>
  );
};

/**
 * Export `MarketsList` component.
 */

export default MarketsList;
