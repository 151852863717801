/**
 * Module dependencies.
 */

import { Button, Loading, Subtitle } from 'src/components/core';
import { Container, Type, media, units } from '@untile/react-components';
import { Fragment } from 'react';
import { GetStaticProps, NextPage } from 'next/types';
import { LayoutProps } from 'src/components/layout';
import { Market } from 'src/types/markets';
import { NewsExhibitionsProps } from 'src/types/news-exhibitions';
import { PageFields } from 'src/types/page-fields';
import { bannersKeys } from 'src/core/content-config/banners';
import { fetchBanner } from 'src/api/banners/fetch-banner';
import { fetchMarkets } from 'src/api/markets/fetch-markets';
import { fetchNewsExhibitions } from 'src/api/news-exhibitions/fetch-news-exhibitions';
import { fetchPageFields } from 'src/api/page-fields/fetch-page-fields';
import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import BannerSection, { Banner } from 'src/components/sections/banner-section';
import MarketsList from 'src/components/pages/markets';
import Metatags from 'src/components/core/metatags';
import NewsExhibitionsList from 'src/components/pages/news-exhibitions/list';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useBanner from 'src/api/banners/use-banner';
import useNewsExhibitions from 'src/api/news-exhibitions/use-news-exhibitions';
import usePageFields from 'src/api/page-fields/use-page-fields';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  banners: Banner[];
  bannersMiddle: Banner[];
  data: PageFields;
  markets: Market[];
  newsExhibitions: {
    pageParams: number[];
    pages: Array<{
      data: NewsExhibitionsProps[];
      nextPage: number | null;
      total: number;
    }>;
  };
};

/**
 * `MarketsWrapper` styled component.
 */

const MarketsWrapper = styled.section`
  padding: ${units(8)} 0;

  ${media.min('md')`
    padding: ${units(11)} 0 ${units(18)};
  `}
`;

/**
 * `NewsExhibitionsWrapper` styled component.
 */

const NewsExhibitionsWrapper = styled.section<{ isLoading: boolean }>`
  padding: ${units(6)} 0 ${units(7.5)};
  position: relative;

  ${media.min('md')`
    padding: ${units(11)} 0 ${units(22)};
  `}

  ${ifProp(
    'isLoading',
    css`
      min-height: 100vh;
    `
  )}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding: 0;
`;

/**
 * `NewsExhibitionsGrid` styled component.
 */

const NewsExhibitionsGrid = styled.div`
  display: grid;
  grid-template-areas:
    '. title    .'
    '. subtitle .'
    '. button   .'
    '. news     .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme(
      'grid.gutterMobile'
    )}px;

  ${media.min('md')`
    grid-template-areas:
      '. title    title    .        .'
      '. subtitle .        button   .'
      '. news     news     news     .';
    grid-template-columns: 1fr 4fr 1fr 5fr 1fr;
    grid-template-rows: repeat(4, max-content);
  `}
`;

/**
 * `NewsTitle` styled component.
 */

const NewsTitle = styled(Type.H2)`
  grid-area: title;

  ${media.min('md')`
    margin-bottom: ${units(1)};
  `}
`;

/**
 * `NewsDescription` styled component.
 */

const NewsDescription = styled(Subtitle)`
  grid-area: subtitle;

  ${media.max('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `NewsButtonWrapper` styled component.
 */

const NewsButtonWrapper = styled.div`
  grid-area: button;

  ${media.min('md')`
    align-self: center;
    display: flex;
    justify-self: flex-end;
  `}
`;

/**
 * `NewsListWrapper` styled component.
 */

const NewsListWrapper = styled.div`
  display: grid;
  grid-area: news;
  grid-gap: ${units(3)};
  grid-template-columns: 1fr;
  padding-top: ${units(5)};

  ${media.min('ms')`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.min('md')`
    grid-gap: ${units(4)};
    padding-top: ${units(9)};
  `}
`;

/**
 * `Home` page.
 */

const Home: NextPage<Props> = props => {
  const { banners, bannersMiddle, data, markets, newsExhibitions } = props;
  const { translate } = useTranslate();
  const newsRouteResolver = useLocalizedRoute();
  const { data: bannersData, isSuccess: isSuccessBanners } = useBanner({
    initialData: banners,
    slug: bannersKeys.homepage
  });

  const { data: bannersMiddleData, isSuccess: isSuccessBannersMiddle } =
    useBanner({
      initialData: bannersMiddle,
      slug: bannersKeys.homepageMiddle
    });

  const {
    data: newsHeaderData,
    isLoading: isLoadingHeaderNews,
    isSuccess: isSuccessHeaderNews
  } = usePageFields({ initialData: data });

  const {
    data: newsData,
    isLoading: isLoadingNews,
    isSuccess: isSuccessNews
  } = useNewsExhibitions({
    initialData: newsExhibitions,
    limit: 3
  });

  const isLoading = isLoadingHeaderNews || isLoadingNews;
  const isSuccess = isSuccessHeaderNews && isSuccessNews;
  const total = newsData?.pages[0]?.total ?? 0;
  const animProps = {
    'data-scroll': 'fade-in-up',
    'data-scroll-offset': '10%'
  };

  return (
    <>
      <Metatags
        description={translate('homepage:description')}
        pageTitle={translate('homepage:title')}
        title={translate('homepage:title')}
      />

      {isSuccessBanners && <BannerSection banners={bannersData} />}

      <MarketsWrapper>
        <MarketsList
          data={data}
          markets={markets}
        />
      </MarketsWrapper>

      {isSuccessBannersMiddle && (
        <BannerSection
          banners={bannersMiddleData}
          isMiddleBanner
        />
      )}

      <NewsExhibitionsWrapper isLoading={isLoading}>
        <Loading active={isLoading} />

        {isSuccess && total > 0 && (
          <StyledContainer>
            <NewsExhibitionsGrid>
              <NewsTitle {...animProps}>{newsHeaderData?.newsTitle}</NewsTitle>

              <NewsDescription {...animProps}>
                {newsHeaderData?.newsDescription}
              </NewsDescription>

              <NewsButtonWrapper {...animProps}>
                <Button
                  colorTheme={'tertiary'}
                  href={newsRouteResolver('newsExhibitions')}
                  size={'small'}
                >
                  {translate('common:actions.viewAll')}
                </Button>
              </NewsButtonWrapper>

              <NewsListWrapper>
                {map(newsData?.pages, ({ data }, index) => (
                  <Fragment key={index}>
                    <NewsExhibitionsList newsExhibitions={data} />
                  </Fragment>
                ))}
              </NewsListWrapper>
            </NewsExhibitionsGrid>
          </StyledContainer>
        )}
      </NewsExhibitionsWrapper>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<Props & LayoutProps> = async ({
  locale
}) => {
  const banners = await fetchBanner({ locale, slug: bannersKeys.homepage });
  const bannersMiddle = await fetchBanner({
    locale,
    slug: bannersKeys.homepageMiddle
  });

  const markets = await fetchMarkets({ locale });
  const newsExhibitions = await fetchNewsExhibitions({
    locale,
    params: {
      limit: 3,
      page: 1
    }
  });

  const data = await fetchPageFields({ locale });

  if (!data) {
    return {
      notFound: true
    };
  }

  return {
    props: {
      banners,
      bannersMiddle,
      data,
      hasMobileScrollDownIndicator: true,
      hasScrollDownIndicator: true,
      markets,
      newsExhibitions: {
        pageParams: [1],
        pages: [newsExhibitions]
      }
    },
    revalidate: 60
  };
};

/**
 * Export `Home` page.
 */

export default Home;
