/**
 * Module dependencies.
 */

import { NewsExhibitionsProps } from 'src/types/news-exhibitions';
import {
  fetchNewsExhibitions,
  paginationLimit
} from './fetch-news-exhibitions';

import { useInfiniteQuery } from 'react-query';
import { useRouter } from 'next/router';

/**
 * Export `InitialData` type.
 */

export type InitialData = {
  pageParams: number[];
  pages: Array<{
    data: NewsExhibitionsProps[];
    nextPage: number | null;
    total: number;
  }>;
};

/**
 * `Options` type.
 */

type Options = {
  filterParams?: any;
  initialData?: InitialData | null;
  limit?: number;
};

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-news-exhibitions`;
};

/**
 * `useNewsExhibitions` hook.
 */

function useNewsExhibitions({ filterParams, initialData, limit }: Options) {
  const { locale } = useRouter();
  const result = useInfiniteQuery(
    actionType({ locale }),
    ({ pageParam = 1 }) =>
      fetchNewsExhibitions({
        locale,
        params: {
          filter: filterParams,
          limit: limit || paginationLimit,
          page: pageParam
        }
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const pageLimit = Math.ceil(allPages[0]?.total / paginationLimit);
        const nextPage = lastPage?.nextPage;

        return nextPage <= pageLimit ? nextPage : false;
      },
      initialData
    }
  );

  return result;
}

/**
 * Export `useNewsExhibitions` hook.
 */

export default useNewsExhibitions;
