/**
 * Module dependencies.
 */

import { Image, RouterLink, Subtitle, Svg, icons } from 'src/components/core';
import { ReactElement } from 'react';
import { color, units } from '@untile/react-components';
import { prop } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  bgColor: string;
  className?: string;
  imageUrl: string;
  slug: string;
  title: string;
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  transition: transform 0.3s ease;
  will-change: transform;
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  grid-area: title;
  margin-bottom: ${units(2)};
  padding-top: ${units(3)};
  transition: transform 0.4s ease;
  will-change: transform;
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  grid-area: icon;
  margin-bottom: ${units(1)};
  padding-top: ${units(2)};
  transition: transform 0.4s ease;
  will-change: transform;
`;

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)<{ bgColor: string }>`
  background-color: ${prop('bgColor')};
  color: ${color('textColor')};
  display: grid;
  grid-template-areas:
    '. title .'
    '. image .'
    '. icon  .';
  grid-template-columns: ${units(3)} 1fr ${units(3)};
  grid-template-rows: repeat(3, max-content);
  outline: none;
  position: relative;
  text-decoration: none;
  transition: transform 0.3s ease;
  will-change: transform;

  &:focus,
  &:hover {
    transform: scale(0.98);

    ${StyledImage} {
      transform: scale(0.98);
    }

    ${TextWrapper} {
      transform: scale(0.965);
    }

    ${Icon} {
      transform: translateX(5px);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  overflow: hidden;
  padding-bottom: 91%;
  position: relative;
`;

/**
 * `MarketCard` component.
 */

const MarketCard = (props: Props): ReactElement => {
  const { bgColor, className, imageUrl, slug, title } = props;
  const { locale } = useTranslate();
  const routeResolver = useLocalizedRoute();

  return (
    <Card
      bgColor={bgColor}
      className={className}
      href={routeResolver('marketDetails', { slug })}
      locale={locale}
    >
      <TextWrapper>
        <Subtitle>{title}</Subtitle>
      </TextWrapper>

      <ImageWrapper>
        <StyledImage
          alt={title}
          fill
          src={imageUrl}
          style={{ objectFit: 'contain' }}
        />
      </ImageWrapper>

      <Icon
        icon={icons.arrowRightLarge}
        size={units(6)}
      />
    </Card>
  );
};

/**
 * Export `MarketCard` component.
 */

export default MarketCard;
